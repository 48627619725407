import React, { Component } from 'react'
import './scss/MainTiles.scss'
export default class MainTiles extends Component {
  render() {
    return (
      <div className="mainTiles">
        <div className="tilesContainer">
          <div className="tile myWorkTile" onClick={()=>{
            window.scrollTo(0, window.outerHeight)
          }}>
            <div className="text">
              Moje prace
            </div>
          </div>
          {/* Pushes grid element by 1 */}
          <div className="blank"></div>

          <a href="https://blog.dziuda.it/"  className="blogTileLink">
            <div className="tile blogTile">
              <div className="text">
                Blog
              </div>
            </div>
          </a>
          <a href="https://www.facebook.com/Hubert-Dziuda-107570980920770" className="facebookTileLink"> 
            <div className="tile facebookTile">
                <div className="text">
                  Facebook
                </div>
            </div>
          </a>
          <a href="https://blog.dziuda.it/contact" className="contactTileLink">
            <div className="tile contactTile">
              <div className="text">
                Kontakt
              </div>
            </div>
          </a>
        </div>
      </div>
    )
  }
}

import React from 'react'
import './scss/MyName.scss'
export default function MyName() {
  return (
    <div className="myName">
      <header>
        <div className="nameContainer">
          <div className="name">
            <span>Hubert Dziuda</span> 
          </div>
          <div className="subName">
            <span>Frontend Developer</span>
          </div>
        </div>
      </header>
    </div>
  )
}

import React, { Component } from 'react'
import './scss/MyWorkView.scss'

const WorkItem = ({item}) => {
  return (
    <div className={`myWorkItem ${item.className}`}>
      <div className="workImage"></div>
      <div className="workDescription">
        <div className="workTitle">
          { item? item.title || 'Tytuł' : 'Tytuł'}
        </div>
        <div className="workContent" dangerouslySetInnerHTML={{__html:item.content}}>
        </div>
        <div className="workButtons">
          <a href={`${item.link}`}>
            <div className="fadeInButton">
              <div className="text">Więcej</div>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default class AboutMeView extends Component {
  items = [
    {
      title: 'Blog();',
      content: `
        <p>
          Blog to mój pierwszy projekt, który odważyłem się opublikować w internecie. Frontend stworzyłem przy pomocy React'a. Backend początkowo chciałem zrealizować przy pomocy Express'a, jednak ostatecznie użyłem PHP. 
        </p>
        <p>
        </p>`, 
      link: 'https://blog.dziuda.it',
      className: 'blogWork'
    },
    // {
    //   title: 'zKlasą',
    //   content: `
    //     <p>
    //       ,,Hej, co było z matmy?" - Klasyka, podczas ostatnich tygodni wakacji 2k20 postanowiłem spróbować swoich sił w tworzeniu aplikacji mobilnych. Z uwagi na ,,względnie" dobrą znajomość JS postanowiłem się za to wziąć przy użyciu React Native.
    //     </p>  
    //   `,
    //   link: 'https://zklasa.dziuda.it',
    //   className: 'zKlasaWork'

    // }
  ]
  render() {
    return (
      <div className="myWorkView">
        <div className="sectionTitle">
          <div className="hoverableLogo">
            <div className="text">
              <span>Moje prace</span>
            </div> 
          </div>
          <div className="sectionThemedDot"></div>
          <div className="sectionThemedDot"></div>
          <div className="sectionThemedDot"></div>
          {/* <div className="sectionThemedDot"></div> */}
          
        </div>
        <div className="sectionItems">
          <div className="miniSectionTitle">
            <span>Moje prace</span>
          </div>
          <WorkItem item={this.items[0]}/>
        </div>
      </div>
    )
  }
}

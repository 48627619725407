import React from 'react';
import MyName from './components/MyName'; 
import MainTiles from './components/MainTiles'; 
// import ContactView from './components/ContactView'; 
import MyWorkView from './components/MyWorkView'; 
import './mainScss/App.scss';

function App() {
  return (
    <div className="app">
      <div className="startView">
        <MyName />
        <MainTiles /> 
        <div className="photoAuthor">
          <a href="https://www.pexels.com/pl-pl/zdjecie/biurko-biuro-dane-dokument-373076/" target="_blank" rel="noopener noreferrer">Zdjęcie</a>
        </div>
      </div>
      <MyWorkView />
      {/* <ContactView /> */}
    </div>
  );
}

export default App;
